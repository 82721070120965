<template>
  <div class="couriers-page">
    <div class="container">
      <breadcrumb :navs="breadcrumbNavs"/>
      <div class="page-header columns is-mobile">
        <div class="column">
          <div class="page-header-title">
            {{ $t('navbar.navs.fulfilment.couriersManagement') }}
          </div>
        </div>
        <div class="column is-9 has-text-right">
          <users-hub-filter :value.sync="hubFilter"/>
          <search-field @search="search"/>
          <b-button
            type="is-primary"
            @click="showCourierFormModal">
            {{ $t('couriers.actions.createAccount') }}
          </b-button>
        </div>
      </div>
      <couriers-table
        :couriers="couriersToDisplay"
        @change-active="onChangeActive"
        @edit="onEditCourier"/>
    </div>
    <b-modal
      :active.sync="courierFormModalActive"
      animation
      hasModalCard
      @close="onCloseCourierFormModal">
      <user-form-handler
        ref="userFormHandler"
        formComponentName="courier-form"
        formComponentUserPropName="courier"
        :selectedUser="selectedCourier"
        createUserListenerName="courier/createCourier"
        updateUserListenerName="courier/updateCourier"
        :createSuccessMessage="$t('couriers.actions.createSuccess')"
        :updateSuccessMessage="$t('couriers.actions.updateSuccess')"/>
    </b-modal>
    <b-loading :active="loadingOverlayVisible"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import pageAuthorizationMixin from '@/mixins/page-authorization-mixin'

const Breadcrumb = () => import('@components/Breadcrumb')
const SearchField = () => import('@components/SearchField')
const CouriersTable = () => import('@components/CouriersTable')
const UserFormHandler = () => import('@components/UserFormHandler')
const UsersHubFilter = () => import('@components/UsersHubFilter')

export default {
  name: 'couriers-page',
  components: {
    Breadcrumb,
    SearchField,
    CouriersTable,
    UserFormHandler,
    UsersHubFilter
  },
  mixins: [pageAuthorizationMixin],
  data() {
    return {
      breadcrumbNavs: [
        {
          label: this.$t('common.home'),
          path: { name: 'home' }
        },
        {
          label: this.$t('navbar.navs.fulfilment.couriersManagement')
        }
      ],
      hubFilter: [],
      searchedCouriers: [],
      lastSearchTerm: null,
      courierFormModalActive: false,
      selectedCourier: null
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading'
    ]),
    ...mapGetters('courier', [
      'couriers'
    ]),
    ...mapGetters('hub', [
      'getHubId'
    ]),
    loadingOverlayVisible() {
      return this.getLoading(
        'courier/createCourier',
        'courier/getCouriers',
        'courier/updateCourier'
      )
    },
    couriersToDisplay() {
      if (this.lastSearchTerm) {
        return this.searchedCouriers
      }
      if (this.hubFilter.length > 0) {
        return this.couriers.filter(courier => {
          return this.hubFilter.includes(this.getHubId(courier.hubShortCode))
        })
      }
      return this.couriers
    }
  },
  created() {
    this.resetCourierStore()
    this.resetHubStore()
    this.resetUserStore()

    this.getHubsRequest()
    // component in the router cannot immediately add event listener to the event bus
    this.newUserRequest()
    this.$nextTick(() => {
      this.$eventBus.$on('user/newUserSuccess', () => {
        this.getCouriersRequest()
      })
    })

    this.watchErrorNotification('createCourierError')
    this.watchErrorNotification('updateCourierError')
  },
  methods: {
    ...mapActions('courier', [
      'resetCourierStore',
      'getCouriersRequest',
      'toggleCourierActiveRequest'
    ]),
    ...mapActions('hub', [
      'resetHubStore',
      'getHubsRequest'
    ]),
    ...mapActions('user', [
      'resetUserStore',
      'newUserRequest'
    ]),
    search(searchTerm) {
      this.lastSearchTerm = searchTerm
      if (!searchTerm) {
        this.searchedCouriers = []
        return
      }

      const search = RegExp(searchTerm, 'i')
      this.searchedCouriers = this.couriers.concat().filter(courier => {
        if (search.test(courier.name)) {
          return true
        } else if (search.test(courier.email)) {
          return true
        } else if (search.test(courier.mobileNumber)) {
          return true
        }
        return false
      })
    },
    showCourierFormModal() {
      this.courierFormModalActive = true
    },
    onChangeActive(courier, value) {
      this.toggleCourierActiveRequest({
        userId: courier.id,
        isActive: value
      })
    },
    onEditCourier(courier) {
      this.selectedCourier = courier
      this.showCourierFormModal()
    },
    onCloseCourierFormModal() {
      this.selectedCourier = null
      this.$refs.userFormHandler.closeNotification()
    }
  }
}
</script>

<style lang="scss" scoped>
.couriers-page {
  .container {
    margin-top: $space-l;

    .page-header {
      align-items: flex-end;

      .page-header-title {
        white-space: nowrap;
        @extend %display_medium;
      }
    }

    .columns > .column:last-child {
      > :not(:first-child) {
        margin-left: $space-xs;
      }

      .button {
        @extend %body;
      }
    }

    .search-field {
      max-width: 160px;
    }
  }
}
</style>
